import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
   email: Yup.string().email('Invalid email').required('Required'),
   location: Yup.string().required('Required'),
   firstName: Yup.string()
      .required('Required')
      .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
   lastName: Yup.string()
      .required('Required')
      .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
   phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
   // updates: Yup.boolean(),
   DoNotCall: Yup.boolean(),
   mercury__SMS_Opt_Out__c: Yup.boolean(),
});
