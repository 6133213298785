import * as Yup from 'yup';

export const quoteSchema = Yup.object().shape(
   {
      first_name: Yup.string()
         .required('Required')
         .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
      last_name: Yup.string()
         .required('Required')
         .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string().min(15, 'Invalid phone number').required('Required'),
      location: Yup.string().required('Required'),
      DoNotCall: Yup.boolean(),
      mercury__SMS_Opt_Out__c: Yup.boolean(),
      bodyAreas: Yup.array().when('description', (val, schema) => {
         // Note: val is an array by default
         if (!val[0] || val[0] === '') {
            return schema
               .min(1, 'At least one body area must be selected if the "Other Areas" field below is empty')
               .max(3, 'No more than 3 body areas to be selected');
         } else {
            return schema.max(3, 'No more than 3 body areas to be selected');
         }
      }),
      description: Yup.string()
         .trim()
         .when('bodyAreas', (val, schema) => {
            // Note: val is an array by default
            if (!val[0] || val[0].length === 0) {
               return schema.required('This field is required if none of the body areas above is selected');
            } else {
               return schema;
            }
         }),
   },
   [['bodyAreas', 'description']],
); // This means "bodyAreas" & "description" are depending on each other // Keep them in this order (IMPORTANT)

// Deprecated - OLD : This one doesn't work with Yup v 1.1.1 or heigher
// export const quoteSchema = Yup.object().shape({
//   first_name: Yup.string().required('Required'),
//   email: Yup.string().email('Invalid email').required('Required'),
//   phone: Yup.string().min(15, 'Invalid phone number').required('Required'),
//   location: Yup.string().required('Required'),
//   bodyAreas: Yup.array().max(3, 'No more than 3 body areas to be selected').ensure().when('description', {
//     is: description => description === "",
//     then: Yup.array().min(1, 'At least one body area must be selected if the "Other Areas" field below is empty')
//   }),
//   description: Yup.string().ensure().when('bodyAreas', {
//     is: bodyAreas => bodyAreas.length === 0,
//     then: Yup.string().required('This field is required if none of the body areas above is selected'),
//     otherwise: Yup.string()
//   })
// }, [["bodyAreas", "description"]]) // This means "bodyAreas" & "description" are depending on each other // Keep them in this order (IMPORTANT)
