import axios from 'axios';
import qs from 'qs';
import { COUPON_FORM_HANDLER, CORPORATE_SITE, hardTrim, GetEmailTemplate, sendEmail } from '../../../utils';

export const onSubmitClaim = (formValues, props, coupon, selectedStore, type) => {
   const htmlTemplateForCouponEmail = GetEmailTemplate.couponCodeEmailTemplate(
      coupon.fetchedData.discount,
      new Date(coupon.fetchedData.expDate.toDate()).toString().slice(0, 15),
   );
   axios({
      method: 'POST',
      url: COUPON_FORM_HANDLER,
      data: qs.stringify({
         first_name: formValues.firstName,
         last_name: formValues.lastName,
         email: formValues.email,
         phone: `+${hardTrim(formValues.phoneMask)}`,
         // '00N1L00000F9eBV': selectedStore.store.salesforceValue,
         '00N1L00000F9eBV': formValues.location,
         DoNotCall: formValues.DoNotCall,
         mercury__SMS_Opt_Out__c: formValues.mercury__SMS_Opt_Out__c,
         oid: process.env.GATSBY_OID,
         lead_source: 'Coupon',
         description: `Claim Discount | CouponCode: ${coupon.fetchedData.couponCode.toUpperCase()} | Discount: ${
            coupon.fetchedData.discount
         }%`,
         retUrl: CORPORATE_SITE + '/coupon',
         Campaign_ID: type === 'offer' ? '7015d000002n11h' : '7015d0000037fvS',
         cloudamp__data__c: localStorage.getItem('cloudamp__data__c'),
      }),
      config: {
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
   })
      .then(
         (res) => console.log(res.status, 'lead created'),
         sendEmail(formValues.email, 'Your Milan Savings!', htmlTemplateForCouponEmail, 'Coupon Claim')
            .then((res) => console.log(res), props.setSubmitting(false), props.setStatus('Success'))
            .catch((err) => console.error(err)),
      )
      .catch((err) => {
         console.error(err);
         props.setSubmitting(false);
         props.setStatus('Failed');
      });
};
