import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import {
   QuoteSuccess,
   bodyAreas,
   quoteInitialValues as initialValues,
   quoteSchema as validationSchema,
   submitQuote,
   FormikControl,
   FormSubmitting,
   FormFailed,
   LocationsDropdownWrapper,
   SubscriptionCheckbox,
} from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { callVerifyHuman } from '../../../utils';

export function QuoteForm({ siteData }) {
   const { executeRecaptcha } = useGoogleReCaptcha();

   // Create an event handler so you can call the verification on button click event or form submit
   const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
         console.log('Execute recaptcha not yet available');
         return;
      }
      const user = await callVerifyHuman('customQuote', executeRecaptcha);
      return user;
      // Do whatever you want with the token
   }, [executeRecaptcha]);

   const onSubmit = async (values, helpers) => {
      helpers.setSubmitting(true);
      const user = await handleReCaptchaVerify();
      console.log('reCAPTCHA user custom quote: ', user);
      if (user === 'bot') {
         helpers.setErrors('bot', true);
         helpers.setSubmitting(false);
         return;
      } else {
         submitQuote(values, helpers, siteData);
      }
   };

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={(formValues, props) => onSubmit(formValues, props, siteData)}
         validateOnChange={false}
      >
         {(formik) => {
            return (
               <Form id="quote-form" className="quote-form w-100 pb-4">
                  {formik.isSubmitting ? (
                     <FormSubmitting />
                  ) : formik.status === 'Success' ? (
                     <QuoteSuccess />
                  ) : formik.status === 'Failed' ? (
                     <FormFailed />
                  ) : (
                     <>
                        <div className="form-inputs-wrapper pt-4">
                           <div className="row gy-3 justify-content-center mx-auto">
                              <div className="col-md-5">
                                 <FormikControl
                                    control="input"
                                    name="first_name"
                                    placeholder="First Name*"
                                    type="text"
                                    role="input"
                                    aria-required
                                 />
                              </div>
                              <div className="col-md-5">
                                 <FormikControl
                                    control="input"
                                    name="last_name"
                                    placeholder="Last Name*"
                                    type="text"
                                    role="input"
                                    aria-required
                                 />
                              </div>
                           </div>
                           <div className="row gy-3 justify-content-center mx-auto pt-3">
                              <div className="col-md-5">
                                 <FormikControl
                                    control="phoneInput"
                                    name="phone"
                                    placeholder="Phone Number*"
                                    type="tel"
                                    role="input"
                                    aria-required
                                 />
                              </div>
                              <div className="col-md-5">
                                 <FormikControl
                                    control="emailInput"
                                    name="email"
                                    placeholder="Your Email*"
                                    role="input"
                                    aria-required
                                 />
                              </div>
                              <div className="col-md-5">
                                 <label htmlFor="location">
                                    Location{' '}
                                    <sup>
                                       <FontAwesomeIcon icon={faAsterisk} />
                                    </sup>
                                 </label>
                                 <LocationsDropdownWrapper {...{ siteData, formik }} fieldName="location" />
                              </div>
                           </div>
                           <p className="text-center p-subhead mt-3 mt-sm-4 mt-lg-5">
                              Please select up to 3 areas you’re interested in.
                           </p>
                           <div className="row justify-content-center mt-4 pt-1 checkboxes">
                              <ul className="col-sm-10 row">
                                 <FormikControl
                                    control="areasCheckboxes"
                                    name="bodyAreas"
                                    options={bodyAreas}
                                    formik={formik}
                                 />
                              </ul>
                           </div>
                           <div className="row justify-content-center mt-4">
                              <div className="col-md-10 row">
                                 <p className="p-subhead text-center">
                                    Other Areas{' '}
                                    <span className="d-none d-md-inline">(we treat almost any area of the body)</span>:
                                 </p>
                                 <FormikControl
                                    control="textarea"
                                    name="description"
                                    placeholder="Describe any other areas you're interested in."
                                    rows="1"
                                 />
                              </div>
                           </div>
                        </div>
                        <div id="custom-quote-checkboxes" className="mt-3">
                           <SubscriptionCheckbox formTitle={'custom-quote'} />
                        </div>
                        <div id="actions-btns" className="row justify-content-center my-3 col-lg-10 m-auto">
                           <div className="col-sm-6 col-lg-4 text-center">
                              <button
                                 className="milan-btn milan-red-btn w-100"
                                 type="submit"
                                 disabled={!formik.isValid}
                              >
                                 Request A Quote
                              </button>
                           </div>
                        </div>
                     </>
                  )}
               </Form>
            );
         }}
      </Formik>
   );
}

export default QuoteForm;
