import React, { useRef, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
   UserInputs,
   SubscriptionCheckbox,
   consultSchema,
   LocationsDropdownWrapper,
   ShowAddressAndLink,
   consultInitialValues,
} from '..';
import {
   SELF_SCHEDULE_FORM_HANDLER,
   STANDARD_INQUIRY_FORM_HANDLER,
   CORPORATE_SITE,
   callVerifyHuman,
} from '../../../utils';
import { FormikControl } from '../formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './Consult.css';
import { FullStory } from '@fullstory/browser';

const CONSULT_TYPE = { q: 'Question', ss: 'Self Schedule' };

export function ConsultationForm({ siteData }) {
   const formRef = useRef(null);
   const cloudampDataRef = useRef(null);

   //used to capture the cloudamp data value and store it in localStorage
   useEffect(() => {
      // Wait for 3 seconds before accessing the value
      const timer = setTimeout(() => {
         if (cloudampDataRef.current) {
            // Set the value of localStorage to the value cloudampDataRef.current.value
            localStorage.setItem('cloudamp__data__c', cloudampDataRef.current.value);
         }
      }, 3000); // 3000 milliseconds = 3 seconds
      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
   }, []);
   const { executeRecaptcha } = useGoogleReCaptcha();

   // Create an event handler so you can call the verification on button click event or form submit
   const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
         console.log('Execute recaptcha not yet available');
         return;
      }
      const user = await callVerifyHuman('corporateConsult', executeRecaptcha);
      return user;
      // Do whatever you want with the token
   }, [executeRecaptcha]);

   // You can use useEffect to trigger the verification as soon as the component being loaded
   // useEffect(() => {
   //   handleReCaptchaVerify();
   // }, [handleReCaptchaVerify]);

   const onSubmit = async (values, helpers) => {
      helpers.setSubmitting(true);
      const user = await handleReCaptchaVerify();
      console.log('reCAPTCHA user consult form: ', user);
      helpers.setSubmitting(false);
      if (user === 'bot') {
         helpers.setErrors('bot', true);
         return;
      } else {
         formRef.current.oid.value = process.env.GATSBY_OID;
         formRef.current.submit();
         // FullStory Event Tracking
         FullStory('trackEvent', {
            name: 'Consultation Form',
            properties: {
               name: 'See Available Times Button Clicked',
               values,
            },
         });
      }
   };

   return (
      <div id="consult-form" className="hr-divider hr-divider-consult position-relative">
         <span id="consult" className="anchor-tag"></span>
         <h2 className="text-center fw-normal">Book Your Free Consult</h2>
         <Formik
            initialValues={consultInitialValues}
            validationSchema={consultSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
         >
            {
               // This way we can get access to all formik props
               (formik) => {
                  return (
                     <Form
                        className="container"
                        ref={formRef}
                        action={
                           formik.values.consultType === CONSULT_TYPE.ss
                              ? SELF_SCHEDULE_FORM_HANDLER
                              : STANDARD_INQUIRY_FORM_HANDLER
                        }
                        method="POST"
                     >
                        <input ref={cloudampDataRef} type="hidden" name="cloudamp__data__c" />
                        <input type="hidden" name="oid" />
                        <input
                           type="hidden"
                           id="success"
                           name="retURL"
                           value={
                              formik.values.consultType === CONSULT_TYPE.ss ? `` : `${CORPORATE_SITE}/form/success/`
                           }
                        />

                        <input type="hidden" name="city" value={`${siteData.selectedStore?.metro?.name}`} />
                        <input type="hidden" name="state" value={`${siteData.selectedStore?.state?.abbreviation}`} />
                        <input type="hidden" name="DoNotCall" value={`${formik.values.DoNotCall}`} />
                        <input
                           type="hidden"
                           name="mercury__SMS_Opt_Out__c"
                           value={`${formik.values.mercury__SMS_Opt_Out__c}`}
                        />

                        <input
                           className="lead_source"
                           type="hidden"
                           name="lead_source"
                           id="lead_source"
                           value={formik.values.consultType === CONSULT_TYPE.ss ? 'Self-Schedule Site' : 'Website'}
                        />
                        <input
                           type="hidden"
                           name="Campaign_ID"
                           id="campaign"
                           value={formik.values.consultType === CONSULT_TYPE.ss ? '7011L000001K6qrQAC' : ''}
                        />

                        <UserInputs formik={formik} />

                        <div className="row locations-container">
                           <div className={`col locations-wrapper ${formik.values.consultType !== '' ? 'toggle' : ''}`}>
                              <div id="locations-dropdown" className="row justify-content-center my-3">
                                 <div className="col-md-6 col-lg-4">
                                    <h3>Select a location from the list</h3>
                                    <LocationsDropdownWrapper {...{ siteData, formik }} fieldName="00N1L00000F9eBV" />
                                 </div>
                              </div>
                              <div className="row justify-content-center selected-location-container mb-3">
                                 <div
                                    className={`col-xl-10 selected-location-wrapper ${
                                       formik.values['00N1L00000F9eBV'] ? 'toggle' : ''
                                    }`}
                                 >
                                    <div className="mb-0 text-center">
                                       {formik.values['00N1L00000F9eBV'] &&
                                          siteData.milanStores.length > 0 &&
                                          siteData.selectedStore.store && (
                                             <ShowAddressAndLink selectedStore={siteData.selectedStore} />
                                          )}
                                       {formik.values.consultType === CONSULT_TYPE.ss &&
                                          !siteData.selectedStore?.store?.open && (
                                             <div className="d-flex justify-content-center mb-3">
                                                <div
                                                   className="alert alert-warning col text-center p-2 mb-0"
                                                   role="alert"
                                                >
                                                   Selected location is not open yet, but you can still
                                                   <span
                                                      onClick={() => {
                                                         formik.setStatus('Question Initiated');
                                                         formik.setFieldValue('consultType', CONSULT_TYPE.q);
                                                      }}
                                                   >
                                                      &nbsp;submit a question
                                                   </span>
                                                   ! Or select a different location.
                                                </div>
                                             </div>
                                          )}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <SubscriptionCheckbox wrapperClass={'mb-4'} formik={formik} />

                        <div id="actions-btns" className="row flex-sm-row-reverse gx-1 gy-2 justify-content-center">
                           <div className="col-sm-5 col-lg-4 col-xl-3 text-center">
                              {
                                 // No button clicked yet / Initial setup
                                 formik.values.consultType === '' && formik.status !== 'Question Initiated' && (
                                    <FormikControl
                                       control="consultRadio"
                                       label="See Available Times"
                                       value={CONSULT_TYPE.ss}
                                       name="consultType"
                                       btnColor="red"
                                    />
                                 )
                              }
                              {
                                 // First See Available Times Button clicked
                                 formik.values.consultType === CONSULT_TYPE.ss && (
                                    <button
                                       id="corporate-schedule-btn"
                                       type="submit"
                                       className="milan-btn milan-red-btn w-100"
                                       disabled={
                                          !formik.isValid ||
                                          formik.status === 'BOT' ||
                                          !siteData.selectedStore?.store?.open
                                       }
                                    >
                                       {formik.isSubmitting ? 'Submitting...' : 'See Available Times'}
                                    </button>
                                 )
                              }
                              {
                                 // Ask A Question button clicked
                                 formik.status === 'Question Initiated' && formik.values.consultType === '' && (
                                    <button
                                       type="button"
                                       className="milan-btn milan-fallback-btn w-100"
                                       onClick={() => {
                                          formik.setFieldValue('consultType', '');
                                          formik.setStatus(undefined);
                                       }}
                                    >
                                       Go Back
                                    </button>
                                 )
                              }
                              {
                                 // CONSULT TYPE is Question
                                 formik.status === 'Question Initiated' &&
                                    formik.values.consultType === CONSULT_TYPE.q && (
                                       <button
                                          type="button"
                                          className="milan-btn milan-fallback-btn w-100"
                                          onClick={() => {
                                             formik.setFieldValue('consultType', '');
                                             formik.setStatus('Question Initiated');
                                          }}
                                       >
                                          Go Back
                                       </button>
                                    )
                              }
                           </div>

                           <div className="d-none d-sm-block col-sm-2 col-lg-1 text-center">
                              <div className="my-3 my-md-2 fw-bold">OR</div>
                           </div>

                           <div className="col-sm-5 col-lg-4 col-xl-3 text-center">
                              {
                                 // No button clicked yet / Initial setup
                                 formik.values.consultType === '' && formik.status !== 'Question Initiated' && (
                                    <button
                                       type="button"
                                       className="milan-btn milan-navy-btn w-100"
                                       id="ask-a-question"
                                       onClick={() => formik.setStatus('Question Initiated')}
                                    >
                                       Ask A Question
                                    </button>
                                 )
                              }
                              {
                                 // First See Available Times Button clicked
                                 formik.values.consultType === CONSULT_TYPE.ss && (
                                    <button
                                       type="button"
                                       className="milan-btn milan-fallback-btn w-100"
                                       onClick={() => {
                                          formik.setFieldValue('consultType', '');
                                          formik.setStatus(undefined);
                                       }}
                                    >
                                       Go Back
                                    </button>
                                 )
                              }
                              {
                                 // Ask A Question button clicked
                                 formik.status === 'Question Initiated' && formik.values.consultType === '' && (
                                    <FormikControl
                                       control="consultRadio"
                                       label="NEXT"
                                       value={CONSULT_TYPE.q}
                                       name="consultType"
                                       btnColor="navy"
                                    />
                                 )
                              }
                              {
                                 // Next button clicked
                                 formik.values.consultType === CONSULT_TYPE.q && (
                                    <button
                                       className="milan-btn milan-navy-btn w-100 g-recaptcha"
                                       type="submit"
                                       id="send-question"
                                       disabled={!formik.isValid}
                                    >
                                       {formik.isSubmitting ? 'Submitting...' : 'Send Question'}
                                    </button>
                                 )
                              }
                           </div>
                        </div>

                        <div className="text-center milan-fp mt-3">
                           This site is protected by reCAPTCHA and the Google{' '}
                           <a
                              href="https://policies.google.com/privacy"
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-underline"
                           >
                              Privacy Policy
                           </a>{' '}
                           and{' '}
                           <a
                              href="https://policies.google.com/terms"
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-underline"
                           >
                              Terms of Service
                           </a>{' '}
                           apply
                        </div>
                     </Form>
                  );
               }
            }
         </Formik>
      </div>
   );
}

export default ConsultationForm;

// -------  Old Logic for Self Schedule retURL --------- //
// <input type="hidden" id="success" name="retURL" value={formik.values.consultType === CONSULT_TYPE.ss ? `${MILAN_SITE_START}.my.site.com/s/?firstname=${formik.values.first_name}&lastname=${formik.values.last_name}&phone=%2b${hardTrim(formik.values.phoneMask)}&email=${formik.values.email}&city=${siteData.selectedStore?.metro?.name}&state=${siteData.selectedStore?.state?.abbreviation}&success_url=${CORPORATE_SITE}/form/self-success-force/` : `${CORPORATE_SITE}/form/success/`} />

// --------------Old reCaptcha Logic -------------------------- //
// Create an event handler so you can call the verification on button click event or form submit
// const handleReCaptchaVerify = useCallback(async () => {
//   if (!executeRecaptcha) {
//     console.log('Execute recaptcha not yet available');
//     return;
//   }
//   const token = await executeRecaptcha('corporateConsult');

//   fetch('/api/verifyHuman', {
//     method: 'POST',
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify({
//       token: token
//     })
//   }).then(res => res.json()).then(res => {
//     console.log('res', res)
//   }).catch(err => console.log(err))
// }, [executeRecaptcha]);

// // You can use useEffect to trigger the verification as soon as the component being loaded
// useEffect(() => {
//   handleReCaptchaVerify();
// }, [handleReCaptchaVerify]);
