export const contactInitialValues = {
   first_name: '',
   last_name: '',
   phoneMask: '',
   email: '',
   '00N1L00000F9eBV': '',
   // updates: true,
   DoNotCall: true,
   mercury__SMS_Opt_Out__c: true,
   description: '',
};
