export const faqsInitialValues = {
   first_name: '',
   last_name: '',
   phone: '',
   email: '',
   location: '',
   // updates: true,
   DoNotCall: true,
   mercury__SMS_Opt_Out__c: true,
   description: '',
};
