export const quoteInitialValues = {
   first_name: '',
   last_name: '',
   phone: '',
   email: '',
   location: '',
   bodyAreas: [],
   description: '',
   DoNotCall: true,
   mercury__SMS_Opt_Out__c: true,
};

export const bodyAreas = ['Brazilian', 'Underarm', 'Upper Lip', 'Chin', 'Face & Neck', 'Legs', 'Back', 'Chest'];
