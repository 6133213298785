import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
   FormikControl,
   LocationsDropdownWrapper,
   SubmitSelfScheduleBtn,
   FormFailed,
   FormSubmitting,
   SubscriptionCheckbox,
} from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { NO_RAZOR, MILAN_PHONE, SELF_SCHEDULE_FORM_HANDLER, hardTrim } from '../../utils';
import useCloudampData from '../../hooks/useCloudampData';
// import {FormikControl, LocationsDropdownWrapper, SubmitBtn, SubmitSelfScheduleBtn, FormFailed, FormSubmitting} from '..';
// import {onSubmitConsult} from './utils/onSubmitConsult';

const validationSchema = Yup.object().shape({
   // name: Yup.string().required('Required'),
   firstName: Yup.string()
      .required('Required')
      .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
   lastName: Yup.string()
      .required('Required')
      .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
   email: Yup.string().email('Invalid email').required('Required'),
   phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
   location: Yup.string().required('Required'),
   DoNotCall: Yup.boolean(),
   mercury__SMS_Opt_Out__c: Yup.boolean(),
});

const Success = ({ coupon }) => (
   <div className="text-center pt-4">
      <h2 className="milan-text-primary subheadlg pb-0">Thank You!</h2>
      <h2 className="milan-text-tur subheadsm">We'll be in touch.</h2>
      <h6 className="py-3">
         You’ve successfully requested a consult with{' '}
         <strong>
            <span className="milan-text-primary">{coupon.fetchedData.discount}%</span>
         </strong>
         &nbsp;discount.
      </h6>
      <p className="px-xl-4">
         Our team is excited to help you get the smooth, hair-free skin that you deserve! We will be reaching out to you
         shortly to schedule a convenient time for you to come in for your free consultation. In the meantime, if you
         have any questions please contact us at{' '}
         <a href={`tel:${MILAN_PHONE}`} className="milan-text-primary">
            {NO_RAZOR}
         </a>
      </p>
   </div>
);

export function BookConsult({ siteData, coupon, type }) {
   const formRef = useRef(null);
   const onSubmit = async (values, helpers) => {
      formRef.current.submit();
   };

   const cloudampData = useCloudampData();

   return (
      <Formik
         initialValues={{
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneMask: '',
            location: '',
            DoNotCall: true,
            mercury__SMS_Opt_Out__c: true,
         }}
         validationSchema={validationSchema}
         // onSubmit={(user, props) => onSubmitConsult(user, props, coupon, type)}
         onSubmit={onSubmit}
         validateOnChange={false}
      >
         {(formik) => {
            return (
               <Form
                  className="w-100 card p-3 mb-5 milan-bg-white"
                  ref={formRef}
                  action={SELF_SCHEDULE_FORM_HANDLER}
                  method="POST"
               >
                  <input type="hidden" name="cloudamp__data__c" value={cloudampData} />
                  <input type="hidden" id="success" name="retURL" value="" />
                  <input type="hidden" name="city" value={`${siteData.selectedStore?.metro?.name}`} />
                  <input type="hidden" name="state" value={`${siteData.selectedStore?.state?.abbreviation}`} />
                  <input type="hidden" name="Campaign_ID" id="campaign" value="7011L000001K6qrQAC" />
                  <input type="hidden" name="oid" value={process.env.GATSBY_OID} />
                  <input type="hidden" name="first_name" value={formik.values.firstName} />
                  <input type="hidden" name="last_name" value={formik.values.lastName} />
                  <input type="hidden" name="email" value={formik.values.email} />
                  <input type="hidden" name="phone" value={`+${hardTrim(formik.values.phoneMask)}`} />
                  {/* this is the location field for the Salesforce */}
                  <input type="hidden" name="00N1L00000F9eBV" id="location" value={formik.values.location} />
                  <input type="hidden" name="DoNotCall" value={formik.values.DoNotCall} />
                  <input type="hidden" name="mercury__SMS_Opt_Out__c" value={formik.values.mercury__SMS_Opt_Out__c} />
                  <input
                     type="hidden"
                     name="description"
                     value={`Direct Mail | Book Consult | CouponCode: ${coupon.fetchedData.couponCode.toUpperCase()} | Discount: ${
                        coupon.fetchedData.discount
                     }%`}
                  />
                  <input type="hidden" name="lead_source" value="Self-Schedule Site" />

                  {formik.isSubmitting ? (
                     <FormSubmitting />
                  ) : formik.status === 'Success' ? (
                     <Success coupon={coupon} />
                  ) : formik.status === 'Failed' ? (
                     <FormFailed />
                  ) : (
                     <>
                        <h2 className="mb-4 subheadsm milan-text-primary">Book Your Consult</h2>
                        <div className="row mb-4 text-start">
                           <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                              <FormikControl control="input" name="firstName" placeholder="First Name*" type="text" />
                           </div>
                           <div className="col-12 col-lg-6">
                              <FormikControl control="input" name="lastName" placeholder="Last Name*" type="text" />
                           </div>
                        </div>
                        <div className="mb-4 text-start">
                           <FormikControl control="emailInput" placeholder="Your Email*" name="email" />
                        </div>
                        <div className="mb-4 text-start">
                           <FormikControl
                              control="phoneInput"
                              placeholder="Phone Number*"
                              name="phoneMask"
                              type="tel"
                           />
                        </div>
                        <div className="mb-4 text-start">
                           <label htmlFor="location">
                              Location{' '}
                              <sup>
                                 <FontAwesomeIcon icon={faAsterisk} />
                              </sup>
                           </label>
                           <LocationsDropdownWrapper {...{ siteData, formik }} fieldName="location" />
                        </div>
                        <SubmitSelfScheduleBtn formik={formik} />
                        <SubscriptionCheckbox formTitle={'book-consult'} grid={'col'} wrapperClass={'my-3'} />
                        {/* <p className="mb-0 mt-3 milan-fp mx-auto">
                           I consent by electronic signature to be contacted by Milan Laser by live agent, email &
                           automatic telephone dialer for information, offers or advertisements via email/ phone call/
                           text message at the number & email provided. No purchase necessary.
                        </p> */}
                     </>
                  )}
               </Form>
            );
         }}
      </Formik>
   );
}

export default BookConsult;
