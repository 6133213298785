import axios from 'axios';
import { navigate } from 'gatsby';
import qs from 'qs';
import { CUSTOM_QUOTE_FORM_HANDLER, CORPORATE_SITE, hardTrim, GetEmailTemplate, sendEmail } from '../../../../utils';

export const submitQuote = (formValues, props, siteData) => {
   const htmlTemplateForCustomQuote = GetEmailTemplate.customQuoteEmailTemplate(
      formValues.first_name,
      siteData.selectedStore.metro.name,
   );
   axios({
      method: 'POST',
      url: CUSTOM_QUOTE_FORM_HANDLER,
      data: qs.stringify({
         first_name: formValues.first_name,
         last_name: formValues.last_name,
         email: formValues.email,
         phone: `+${hardTrim(formValues.phone)}`,
         '00N1L00000F9eBV': formValues.location,
         oid: process.env.GATSBY_OID,
         lead_source: 'Custom Quote',
         description: `Instant Estimate: ${formValues.bodyAreas.join(' | ')} | ${formValues.description}`,
         retUrl: CORPORATE_SITE + '/specials/estimate',
         Campaign_ID: '70141000000TgDG',
         cloudamp__data__c: localStorage.getItem('cloudamp__data__c'),
         DoNotCall: formValues.DoNotCall,
         mercury__SMS_Opt_Out__c: formValues.mercury__SMS_Opt_Out__c,
      }),
      config: {
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
   })
      .then(
         (res) => console.log(res.status, 'lead created'),
         sendEmail(formValues.email, 'Milan Laser Custom Quote', htmlTemplateForCustomQuote, 'Custom Quote')
            .then(
               (res) => console.log(res),
               props.setSubmitting(false),
               props.setStatus('Success'),
               navigate('?quote-form-success'),
            )
            .then(
               (res) => console.log(res),
               props.setSubmitting(false),
               props.setStatus('Success'),
               navigate('?quote-form-success'),
            )
            .catch((err) => console.error(err)),
      )
      .catch((err) => {
         console.error(err);
         props.setSubmitting(false);
         props.setStatus('Failed');
      });
};
