import React from 'react';
import { Field } from 'formik';
import { CORPORATE_SITE } from '../../../utils';

// export function ConsentCheckbox(props) {
//   const { label, name, mailchimpID, ...rest } = props
//   return (
//     <Field name={name}>
//       {
//         props => {
//           const { field } = props
//           return (
//             <label htmlFor={mailchimpID}>
//               By checking this box, you agree to Milan Laser Hair Removal to reach you by phone or text with information about our services, Msg & Data Rates May Apply. Reply Stop to cancel. View our <Link to="/privacy/" className="milan-text-navy text-decoration-underline">Privacy Policy</Link>.
//               <input
//                 type="checkbox"
//                 {...field} {...rest}
//                 value={field.value}
//                 id={mailchimpID}
//                 checked={field.value}/>
//               <span className="checkmark"></span>
//             </label>
//           )
//         }
//       }
//     </Field>
//   )
// }

export function ConsentCheckbox(props) {
   const { label, name, mailchimpID, variant, ...rest } = props;
   return (
      <Field name={name}>
         {(props) => {
            const { field, form } = props;
            return (
              <label htmlFor={mailchimpID} className="text-start">
                  By checking this box and providing your phone number, you agree to Milan Laser Hair Removal to reach
                  you by {variant} with information about our services.{' '}
                  {variant === 'text' &&
                     'Msg frequency may vary, Msg & data rates may apply. Reply HELP for help. Reply Stop to cancel.'}{' '}
                  View our{' '}
                  <a
                     href={CORPORATE_SITE + `/privacy/`}
                     target="_blank"
                     rel="noreferrer"
                     className="text-decoration-underline"
                  >
                     Privacy&nbsp;Policy
                  </a>{' '}
                  &{' '}
                  <a
                     href={CORPORATE_SITE + `/terms/`}
                     target="_blank"
                     rel="noreferrer"
                     className="text-decoration-underline"
                  >
                     Terms and Conditions
                  </a>
                  .
                  <input
                     type="checkbox"
                     {...field}
                     {...rest}
                     // value={field.value}
                     id={mailchimpID}
                     checked={!field.value}
                     onChange={() => form.setFieldValue(name, !field.value)}
                  />
                  <span className="checkmark"></span>
               </label>
            );
         }}
      </Field>
   );
}

export default ConsentCheckbox;
