import React from 'react';
import { FormikControl } from '..';

export function SubscriptionCheckbox({ grid, formTitle, wrapperClass }) {
   return (
      <div className={`row justify-content-center subscription-checkboxes ${wrapperClass || ''}`}>
         <div className={`${grid || 'col-xl-10'} d-flex flex-column custom-checkbox`}>
            {/* <FormikControl control="consentCheckbox" name="updates" mailchimpID="mailchimp-consult" /> */}
            <div className="pb-2">
               <FormikControl
                  control="consentCheckboxPhone"
                  name="DoNotCall"
                  mailchimpID={`mailchimp-${formTitle || 'consult'}-phone`}
               />
            </div>
            <FormikControl
               control="consentCheckboxSMS"
               name="mercury__SMS_Opt_Out__c"
               mailchimpID={`mailchimp-${formTitle || 'consult'}-sms`}
            />
         </div>
      </div>
   );
}

export default SubscriptionCheckbox;
