import qs from 'qs';
import axios from 'axios';
import { FAQS_FORM_HANDLER, CORPORATE_SITE, hardTrim } from '../../../../utils';
import { navigate } from 'gatsby';

export const submitFaqsForm = (values, helpers) => {
   axios({
      method: 'POST',
      url: FAQS_FORM_HANDLER,
      data: qs.stringify({
         first_name: values.first_name,
         last_name: values.last_name,
         description: values.description,
         phone: `+${hardTrim(values.phone)}`,
         email: values.email,
         updates: values.updates,
         '00N1L00000F9eBV': values.location,
         oid: process.env.GATSBY_OID,
         lead_source: 'FAQ Submission',
         retUrl: CORPORATE_SITE + '/process/faqs/',
         Campaign_ID: '7011L000001hWzN',
         cloudamp__data__c: localStorage.getItem('cloudamp__data__c'),
         DoNotCall: values.DoNotCall,
         mercury__SMS_Opt_Out__c: values.mercury__SMS_Opt_Out__c,
      }),
      config: {
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
   })
      .then((res) => {
         console.log(res.status, 'Faqs form submitted');
         helpers.setSubmitting(false);
         helpers.setStatus('Success');
         navigate('?faqs-form-success#faqs-form');
      })
      .catch((err) => {
         console.error(err);
         helpers.setSubmitting(false);
         helpers.setStatus('Failed');
      });
};
